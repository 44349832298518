<script>
import faq from '~/components/layout/faq'

export default {
  name: 'VenelainaFAQAccordion',
  extends: faq,
  data () {
    return {
      questions: [
        {
          title: 'Kuka voi hakea venelainaa?',
          content:
            `Rahoitusta veneeseen voi hakea täysi-ikäinen, säännölliset tulot omaava henkilö,
            jolla on luottotiedot kunnossa.`
        },
        {
          title: 'Kuinka paljon voin hakea lainaa venelainaa?',
          content:
            'Rahalaitoksen kautta voit hakea lainaa venettä varten 500 eurosta aina 60 000 euroon asti.'
        },
        {
          title: 'Sitoudunko ottamaan lainan?',
          content:
            `Lainahakemuksen täyttäminen ja lainatarjousten vertailu ei sido tai hakijaa mihinkään. Jos päätät
            hyväksyä jonkun sinulle tehdyistä lainatarjouksista, tapahtuu sopimuksen allekirjoitus erikseen.`
        },
        {
          title: 'Tarvitsenko vakuuksia tai takaajia?',
          content:
            'Et. Säännölliset palkka- tai eläketulot ja maksuhäiriövapaat luottotiedot toimivat vakuutenasi.'
        },
        {
          title: 'Mistä löydän edullisen venelainan?',
          content:
            `Löydät edullisen lainan Rahalaitoksen sivuilta kilpailuttamalla yhdellä hakemuksella yli 20 pankkia ja
            luotontarjoajaa. Saamistasi tarjouksistasi saat itse valita sopivimman.`
        },
        {
          title: 'Kuinka nopeasti saan rahat käyttöön?',
          content:
            'Rahat siirretään tilillesi nopeasti, usein saman päivän aikana lainasopimuksen allekirjoitettuasi.'
        },
        {
          title: 'Mikä on pisin laina-aika?',
          content:
            'Pisin laina-aika on 180 kuukautta.'
        },
        {
          title: 'Kuinka kallis on venelaina?',
          content:
            `Todellinen vuosikorko on aina henkilökohtainen. Oman korkotasosi tarkistat sitoutumatta täyttämällä helpon
            lainahakemuksen Rahalaitos.fi sivustolta.`
        }
      ]
    }
  }
}
</script>
